import React from 'react';
import Button from '@mui/material/Button';
import Style from "../Styles/SetEncryptionValuesButtonStyle";

export default function BytesButton (props) {
    const classes = Style();

    const componentConstants = {
        label: props.componentConstants.label,
    }
    const componentProps = {
        ...props.componentProps,
        style: classes.root,
        size: "large",
        fullWidth: true,
        color: "secondary",
        variant: "contained"
    }

    return <Button {...componentProps}>
                {componentConstants.label}
            </Button>
}
import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import SumInputTextField from "./MaterialComponents/SumInputTextField";
import EncryptionInputTextField from "./MaterialComponents/EncryptionInputTextField";
import EncryptSwitch from "./MaterialComponents/EncryptSwitch";
import Grid from '@mui/material/Grid';
import SetEncryptionValuesButton from "./MaterialComponents/SetEncryptionValuesButton";
import Paper from '@mui/material/Paper';
var CryptoJS = require("crypto-js");

const classes = {
    root: {
        '& .MuiTextField-root': {
            margin: 8,
            width: '25ch',
        },
    },
    gridItem: {
        padding: 10
    },
    gridItemFullWidth: {
        padding: 10
    }
};

class LexieEncryptionDialogComponent extends CoreComponent {
    constructor(props) {
        super(props);
        this.state = {
            EncryptionInputTextField: [],
            SumInputTextField: [],
            encryptionArrayValues: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            sumArrayValues: [0, 1, 2, 3, 4, 5, 6, 7],
            appendToOnes: true,
            errorsObject: {
                sum : false,
                encryption: false
            },
            language: props.componentConstants.language
        }
        this.loadComponentsProps()

    }
    componentDidMount() {
        super.componentDidMount()
    }
    setEncryption(object) {
        this.setEncryption(object)
    }
    loadComponentsProps(reload = false) {
        let that = this
        this.setComponentsProps('onesButtons', [])
            .setComponentsProps('zerosButtons', [])
            .setComponentsProps('sumInputs', [])
            .setComponentsProps('gridItem', {
                xs: 12,
                lg: 6,
                md: 12,
                style: classes.gridItem
            })
            .setComponentsProps('gridItemFullWidth', {
                xs: 12,
                lg: 12,
                md: 12,
                style: classes.gridItemFullWidth
            })
            .setComponentsProps('typography', {
                variant:"h6",
                component:"h6"
            })
            .setComponentsProps('gridItem3', {
                item: true,
                xs:3,
                lg:3,
                md:3
            })
            .setComponentsProps('gridItem10', {
                item: true,
                xs:10,
                lg:10,
                md:10
            })
            .setComponentsProps('gridItem2', {
                item: true,
                xs:2,
                lg:2,
                md:2
            })
            .setComponentsProps('gridItem9', {
                item: true,
                xs:9,
                lg:9,
                md:9
            })
            .setComponentsProps('gridItem12', {
                item: true,
                xs:12,
                lg:12,
                md:12
            })
            .setComponentsConstants('this', {
                arrayValues: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                sumArrayValues: [0, 1, 2, 3, 4, 5, 6, 7],
                sumCount: [0, 1, 2, 3, 4, 5, 6, 7],
            })
            .setComponentsProps('setEncryptionValuesButton', {

            })
            .setComponentsConstants('setEncryptionValuesButton', {
                label: that.props.componentMethods.getTranslations().LANG_SET_ENCRYPTION
            })
            .setComponentsProps('setEncryptionValuesButton', {
                onClick: () => {
                        this.calculateEncryption()
                    }
            })
            .loadComponentsPropsButtons('onesButtons')
            .loadComponentsPropsButtons('zerosButtons')
            .setComponentsConstants('EncryptionInputTextField',{

            }).setComponentsMethods('EncryptionInputTextField', {
            })
            .setComponentsProps('EncryptionInputTextField', {
                onKeyPress: (event) => { this.onKeypressEncryptionInputTextField(event) },
                id: "encryption-input-text-field",
                maxLength: 7,
                label: that.props.componentMethods.getTranslations().LANG_ENCRYPTION_BYTE,
                fullWidth: true,
                error: this.state.errorsObject.encryption,
                InputProps: {
                    inputProps: {
                        maxLength: 7,
                        type: "password",
                    }
                },
            })
            .setComponentsConstants('SumInputTextField',{

            }).setComponentsMethods('SumInputTextField', {
            })
            .setComponentsProps('SumInputTextField', {
                onKeyPress: (event) => { this.onKeypressSumInputTextField(event) },
                id: "sum-input-text-field",
                error: this.state.errorsObject.sum,
                label: that.props.componentMethods.getTranslations().LANG_ENCRYPTION_SUM,
                fullWidth: true,
                InputProps: {
                    inputProps: {
                        type: "password",
                    }
                },
            })
            .setComponentsProps('EncryptSwitch', {
                color: "secondary",
                name: "useOnes",
                inputProps: { 'aria-label': 'primary checkbox' },
                checked: this.state.appendToOnes,
                onChange: (e) => { this.setState({appendToOnes: (!this.state.appendToOnes)}, () => {
                        this.loadComponentsProps(true)
                    }
                )}
            })
            .setComponentsConstants('EncryptSwitch', {
                labelLeft: '0',
                labelRight: '1'
            })
            .loadSumValues()

        if(reload) {
            this.refresh()
        }
        return this
    }
    loadComponentsPropsButtons(componentName) {
        let buttons = this.getComponentsProps(componentName)
        let arrayToUse = this.state.ones;
        let stateKey = 'ones'
        let arrayToCheck = this.state.zeros;

        switch (componentName) {
            case 'encryptionInputs':
                break;
        }

        this.setComponentsConstants(componentName, buttons)
        return this
    }

    loadSumValues() {
        let sumInputs = this.getComponentsProps('sumInputs')
        this.getComponentConstants('this').sumArrayValues.map((item, index) => {
            const sumComponentProps = {
                componentProps: {
                    color: 'primary'
                },
                componentMethods: {
                    setSum: (idx, value) => { this.setSum(idx, value)}
                },
                componentConstants: {
                    value: item,
                    sumPosition: index,
                }
            }
            sumInputs[index] = sumComponentProps;
            return null;
        })

        this.setComponentsConstants('sumInputs', sumInputs)
        return this
    }

    getSum() {
        this.calculateEncryption()
        return parseInt(this.state.sum.join(''))
    }

    setSum(idx, value) {
        if(value === '') {
            this.removeFromSum(idx)
        } else {
            let currentSum = this.state.sum
            let sum = [0,1,2,3,4,5,6,7].map((item,i)=> {
                if(i === idx)
                    return value
                else
                if(this.state.sum[i] !== undefined && this.state.sum[i] !== null)
                    return this.state.sum[i];
                else
                    return null
            })
            this.setState({sum: sum})
        }
    }
    removeFromSum(valuePos) {
        let sum = this.state.sum
        sum[valuePos] = null
        this.setState({sum:sum})
    }

    onKeypressSumInputTextField(event) {
        const value = parseInt(event.key)
        if(this.state.sumArrayValues.indexOf(value) > -1) {
            this.appendToSum(value)
        }
        return this
    }
    onKeypressEncryptionInputTextField(event) {
        const value = parseInt(event.key)
        if(this.state.encryptionArrayValues.indexOf(value) > -1) {
            this.appendToStateArray(value, 'EncryptionInputTextField')
        }
        return this
    }

    appendToStateArray(value, stateKey) {
        let stateObject = {
            [stateKey]: this.state[stateKey]
        }
            if(stateObject[stateKey].indexOf(parseInt(value)) === -1) {
            stateObject[stateKey].push(parseInt(value))
            this.setState(stateObject, () => {this.loadComponentsProps(true)} )
        }
        return this
    }

    appendToSum(value) {
        let stateObject = {
            SumInputTextField: this.state.SumInputTextField
        }
        if(this.state.sumArrayValues.indexOf(parseInt(value)) > -1) {
            stateObject.SumInputTextField.push(parseInt(value))
            this.setState(stateObject, () => {this.loadComponentsProps(true)} )
        }
        return this
    }
    removeFromStateArray(value, stateKey) {
        let stateObject = {
            [stateKey]: this.state[stateKey]
        }
        let valuePos = stateObject[stateKey].indexOf(value)
        if( valuePos > -1) {
            stateObject[stateKey].splice(valuePos, 1)
            this.setState(stateObject, () => {
                this.getRender()
                this.refresh()
            } )
        }
        return this
    }

    getRender() {
        return <Paper style={{padding: 8}}>
                    <Grid container spacing={2}>
                        <Grid {...this.getComponentsProps('gridItem10')}>
                            <EncryptionInputTextField {...this.buildComponentProps('EncryptionInputTextField')} />
                        </Grid>
                        <Grid {...this.getComponentsProps('gridItem2')}>
                            <EncryptSwitch {...this.buildComponentProps('EncryptSwitch')} />
                        </Grid>
                        <Grid {...this.getComponentsProps('gridItem12')}>
                            <SumInputTextField {...this.buildComponentProps('SumInputTextField')} />
                        </Grid>
                        <Grid {...this.getComponentsProps('gridItem12')}>
                            <SetEncryptionValuesButton {...this.buildComponentProps('setEncryptionValuesButton')} />
                        </Grid>
                    </Grid>
                </Paper>
    }

    calculateEncryption() {
        const object = {
            ones: [],
            zeros: [],
            sum: this.state.SumInputTextField
        }
        let errorsObject = {
            sum : false,
            encryption: false
        }

        if(object.sum.length === 0) {
            errorsObject.sum = true
        }

        let ones = this.state.encryptionArrayValues.map((value, index) => {
            if(this.state.encryptionArrayValues.indexOf(value) === -1) {
                errorsObject.encryption = true
            }
            if(this.state.EncryptionInputTextField.indexOf(value) === -1) {
                if(this.state.appendToOnes) {
                    object.zeros.push(value)
                } else {
                    object.ones.push(value)
                }
            } else {
                if(this.state.appendToOnes) {
                    object.ones.push(value)
                } else {
                    object.zeros.push(value)
                }
            }
            return null
        })
        if(object.ones.length === 0 || object.zeros.length === 0) {
            errorsObject.encryption = true
        }
        if(errorsObject.sum || errorsObject.encryption) {
            this.setState({errorsObject: errorsObject}, () => {
                this.loadComponentsProps(true)
            })
        } else {
            this.props.componentMethods.setEncryption(object)
        }
    }

    checkExistingItem(value, array) {
        return (array.indexOf(value) > -1)
    }
}
export default LexieEncryptionDialogComponent;
import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/FileEncrypterStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import PasswordIcon from '@mui/icons-material/Password';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Encrypter from './Components/Encrypter';
import Decrypter from './Components/Decrypter'
import LexieEncryption from '../../../../Vendor/LexieEncryption/LexieEncryption';
import LexieEncryptionDialogComponent from '../../../../CommonComponents/LexieEncryptionDialog/LexieEncryptionDialogComponent';
export default class FileEncrypterComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants,
            labelNewButton: this.props.getTranslations().LANG_NEW,
            labelCopyButton: this.props.getTranslations().LANG_COPY,
            labelDownloadButton: this.props.getTranslations().LANG_DOWNLOAD,
            labelLoadButton: this.props.getTranslations().LANG_LOAD_FILE,
            labelTextField: this.props.getTranslations().LANG_JSON_ENCRYPTED_TEXT,
            jsonInputValue:  JSON.stringify(this.state.passwordManagerArray),
            lexieEncryption:  this.state.encryption,
            labelEncryptorTitle:  this.props.getTranslations().LANG_ENCRYPT,
            labelDecryptorTitle:  this.props.getTranslations().LANG_DECRYPT,
            encryptingText:  this.props.getTranslations().LANG_ENCRYPTING
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getTranslations: () => {
                return that.props.getTranslations()
            },
            changeLanguage: (language) => {
                return that.props.changeLanguage(language)
            },
            setEncryption(object) {
                const encryption = new LexieEncryption(object.zeros, object.ones, parseInt(object.sum.join('')))
                that.setState({encryption: encryption}, () => {
                    that.loadComponentsProps(true)
                })
            }
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                    <Typography variant="h3" >{this.props.getTranslations().LANG_FILE_EXCRYPTION}</Typography>
                    {this.state.encryption ?
                        <React.Fragment>
                            <Encrypter {...this.buildComponentProps('Appbar')} />
                            <Decrypter {...this.buildComponentProps('Appbar')} />
                        </React.Fragment>
                    :
                        <LexieEncryptionDialogComponent {...this.buildComponentProps('Appbar')} />
                    }
                    </div>
                </div>
    }
}

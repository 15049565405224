import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/HomeStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import PasswordIcon from '@mui/icons-material/Password';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import TextsmsIcon from '@mui/icons-material/Textsms';

export default class UserOrderComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getTranslations: () => {
                return that.props.getTranslations()
            },
            changeLanguage: (language) => {
                return that.props.changeLanguage(language)
            }
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                            m: 1,
                            width: 256,
                            height: 256,
                            },
                        }}
                        >
                        <Paper style={{textAlign: "center"}}>
                            <a href={(this.props.urlParams.language ? "/" + this.props.urlParams.language  : "") + '/encryption'} color="primary" style={classes.menuDrawerLinks}>
                                <InfoIcon color="secondary"  sx={{ fontSize: 128 }}/>
                                <br />
                                {this.props.getTranslations().LANG_ABOUT_LEXIE_ENCRYPTION}
                            </a>
                        </Paper>
                        <Paper style={{textAlign: "center"}}>
                            <a href={(this.props.urlParams.language ? "/" + this.props.urlParams.language  : "") +  '/password-manager'} color="primary" style={classes.menuDrawerLinks}>
                                <PasswordIcon color="secondary"  sx={{ fontSize: 128 }}/>
                                <br />
                                {this.props.getTranslations().LANG_PASSWORD_MANAGER}
                            </a>
                        </Paper>
                        <Paper style={{textAlign: "center"}}>
                            <a href={(this.props.urlParams.language ? "/" + this.props.urlParams.language  : "") + '/file-encryption'} color="primary" style={classes.menuDrawerLinks}>
                                <EnhancedEncryptionIcon color="secondary"  sx={{ fontSize: 128 }}/>
                                <br />
                                {this.props.getTranslations().LANG_FILE_EXCRYPTION}
                            </a>
                        </Paper>
                        <Paper style={{textAlign: "center"}}>
                            <a href="https://chat.lexie.xyz" color="primary" style={classes.menuDrawerLinks}>
                                <TextsmsIcon color="secondary"  sx={{ fontSize: 128 }}/>
                                <br />
                                {this.props.getTranslations().LANG_LEXIE_CHAT}
                            </a>
                        </Paper>
                        </Box>
                    </div>
                </div>
    }
}

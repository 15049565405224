import React from 'react';
import Button from '@mui/material/Button';
import Style from "../Styles/SetEncryptionValuesButtonStyle";
import Switch from '@mui/material/Switch';


export default function BytesButton (props) {
    const classes = Style();

    const componentConstants = {
        ...props.componentConstants,
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const componentProps = {
        ...props.componentProps,
        style: classes.root,
    }

    return <React.Fragment>
                <Switch
                    {...componentProps}
                 />
                {componentProps.checked ? '1':'0'}
            </React.Fragment>
}

import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Settings from '../../Settings/Settings';
import AppbarSubcomponent from './Subcomponents/Appbar'
import Style from './Styles/AppbarStyle'
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';

export default class Appbar extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
            urlParams: props.componentConstants.urlParams
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getSubscribersAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            hasAccess: (accessLevel) => {
                return that.hasAccess(accessLevel)
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            },
            getSubscribersFeedAction: () => {
                that.props.componentMethods.getSubscribersFeedAction()
            },
            getLinksAction: (q) => {
                that.props.componentMethods.getLinksAction(q)
            },
            postSubscriberKeyword: (keyword) => {
                that.postSubscriberKeyword(keyword)
            },
            deleteSubscriberKeyword: (subscribersID) => {
                that.deleteSubscriberKeyword(subscribersID)
            },
            getTranslations: () => {
                return that.props.componentMethods.getTranslations()
            },
            changeLanguage: (language) => {
                return that.props.componentMethods.changeLanguage(language)
            }
        })
    }

    postSubscriberKeyword(subscribersKeyword) {
        const that = this;
        let postObj = {
            subscribersKeyword: subscribersKeyword,
            subscribersDeliveryPeriod: 0,
            subscribersActive: 1
        }
        if(that.getLocalStorage('user')) {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '?accountToken=' + that.getLocalStorage('user').tokensHash,postObj,'POST',null, (data) => {
                let subscribers = that.props.componentMethods.getManagerState().subscribers;
                subscribers = subscribers.concat(data.items[0])
                that.props.componentMethods.setManagerState({subscribers: subscribers})
            })
        }    
    }

    deleteSubscriberKeyword(subscribersID) {
        const that = this;
        if(that.getLocalStorage('user')) {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '/' + subscribersID + '?accountToken=' + that.getLocalStorage('user').tokensHash,{},'DELETE',null, (data) => {
                that.getSubscribersAction()
            })
        }    
    }
    getSubscribersAction() {
        const that = this;
        that.props.componentMethods.setManagerState({loadingSubscribers: true},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '?accountToken=' + that.getLocalStorage('user').tokensHash,{},'GET','subscribersFeed', (data) => {
                    that.props.componentMethods.setManagerState({subscribers: data.items, loadingSubscribers: false})
                })
            }    
        })
    }

    getRender() {
        const classes = Style()
        this.loadComponentsProps()
        return <div style={classes.root} id="rootComponent">
                    <AppbarSubcomponent {...this.buildComponentProps("Appbar")} />
                    {this.props.componentMethods.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.componentMethods.clearErrorMessage}/>
                     })}
                </div>
    }
}

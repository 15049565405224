export default function Style(theme) {
    const style = {
        root: {
            flexGrow: 1,
            padding: 20
        },
        paper: {
            flexGrow: 1,
            padding: 16,
            margin: '0 auto',
        },
        textAreaPassword: {
            margin: "8px auto"
        },
        typographyCopied: {
            color: '#000'
        }
    }
    return style
}
import React, { useEffect } from 'react';
import Languages from "../../../Languages/Languages"
import Settings from '../Settings/Settings';
import { useParams
} from "react-router-dom";
import { Routes ,Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import PasswordManagerComponent from '../Components/PasswordManager/PasswordManager';
import FileEncrypterComponent from '../Components/FileEcrypter/FileEncrypter';
import AboutLexieEncryption from '../Components/AboutLexieEncryption/AboutLexieEncryption';

function getTranslations(lang)
{
    if (lang)
        return Languages[lang]
    else
        return Languages[Settings.defaultLanguage]
}

function HomePage (props) {
    let urlParams = useParams();

    return <React.Fragment>
                <Home {...props} urlParams={urlParams} getTranslations={() => {return getTranslations(urlParams.language)}} />
            </React.Fragment>  
}

function PasswordManagerPage (props) {
    let urlParams = useParams();

    return <React.Fragment>
                <PasswordManagerComponent {...props} urlParams={urlParams} getTranslations={() => {return getTranslations(urlParams.language)}} />
            </React.Fragment>  
}

function FileEncrypterPage (props) {
    let urlParams = useParams();

    return <React.Fragment>
                <FileEncrypterComponent {...props} urlParams={urlParams} getTranslations={() => {return getTranslations(urlParams.language)}} />
            </React.Fragment>  
}

function AboutLexieEncryptionPage(props)
{
    let urlParams = useParams();

    return <React.Fragment>
                <AboutLexieEncryption {...props} urlParams={urlParams} getTranslations={() => {return getTranslations(urlParams.language)}} />
            </React.Fragment>  
}
export default function RouterSwitch(props) {
    function changeRoute(route) {
        document.location.href = route;
    }

    function changeLanguage(language) {
        let href = document.location.pathname
        let hrefArray = href.split('/')
        hrefArray[1] = language
        href = hrefArray.join("/")
        document.location.pathname = href
    }
    return (<Routes>
                <Route path="/:language/password-manager" element={<PasswordManagerPage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
                <Route path="/:language/file-encryption" element={<FileEncrypterPage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
                <Route path="/:language/encryption" element={<AboutLexieEncryptionPage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
                <Route path="/:language" element={<HomePage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
                <Route path="/password-manager" element={<PasswordManagerPage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
                <Route path="/file-encryption" element={<FileEncrypterPage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
                <Route path="/encryption" element={<AboutLexieEncryptionPage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
                <Route path="/" element={<HomePage {...props} changeRoute={changeRoute} changeLanguage={changeLanguage} />} />
            </Routes>)
}
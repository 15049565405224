import React from 'react';
import Button from '@mui/material/Button';
import Style from "../Styles/BytesButtonStyle";

export default function BytesButton (props) {
    const classes = Style();

    const componentConstants = {
        value: props.componentConstants.value
    }
    const componentProps = {
        style: classes.root,
        size: "small",
    }

    return <Button {...componentProps} {...props.componentProps} onClick={props.componentMethods.onClick}>
                {props.componentConstants.label}
            </Button>
}
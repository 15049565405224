import React from 'react';
import TextField from '@mui/material/TextField';
import Style from "../Styles/SumInputTextFieldStyle";

export default function OnesButton (props) {
    const classes = Style();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentProps = {
        ...props.componentProps,
        style: classes.root
    }

    const setSum = function (e) {
        props.componentMethods.setSum(props.componentConstants.sumPosition, e.target.value)
    }
    return <TextField {...componentProps} />
}
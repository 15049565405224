import React, { useRef, useState, useEffect } from 'react';
import Style from "../Styles/PasswordFieldsListStyle";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StringParser from '../../../../../Vendor/LexieEncryption/StringParser'
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

export default function PasswordFiledsList(props) {
    const classes = Style();

    const componentProps = {
        ...props.componentProps,
        componentText: "Text"
    }
    const componentConstants = {
        ...props.componentConstants
    }
    const [copySuccess, setCopySuccess] = useState('');
    const [passwordInputClass, setPasswordInputClass] = useState(classes.inputPassword);
    const [passwordInputType, setPasswordInputType] = useState('password');
    const textAreaRef = useRef(null);

    useEffect(() => {
        if(passwordInputType === 'text') {
            const originalValue = textAreaRef.current.value
            let parser = new StringParser(props.componentConstants.encryption, textAreaRef.current.value);
            let decryptedText = parser.decryptByteArray(textAreaRef.current.value.split(' '))
            textAreaRef.current.value = decryptedText
            textAreaRef.current.select();
            document.execCommand('copy');
            textAreaRef.current.value = originalValue
            setPasswordInputType('password')
            setCopySuccess(componentConstants.labelCopied);
        }
      });

    function copyToClipboard(e) {
        setPasswordInputType('text')

    };
    return ( <ListItem>
                    <ListItemIcon>
                        <IconButton onClick={copyToClipboard}>
                            <FileCopyIcon color="secondary" />
                        </IconButton>
                    </ListItemIcon>
                    <ListItemIcon>
                    <IconButton onClick={props.componentMethods.removeFromPasswordManager}>
                        <DeleteIcon color="secondary" />
                    </IconButton>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{component:"div"}}
                        secondaryTypographyProps={{component: "div"}}
                        primary={
                            <React.Fragment>
                                <TextField style={{margin: "8px auto"}} type="text" value={props.componentConstants.title}
                                    fullWidth label={componentConstants.labelTitle}
                                    InputProps={{
                                        readOnly: true,
                                      }}
                                />
                                <TextField style={{margin: "8px auto", display:'none'}} multiline rows={4} type="text"
                                    value={props.componentConstants.description}
                                    fullWidth label={componentConstants.labelDescription}
                                    InputProps={{
                                                readOnly: true,
                                              }}
                                />
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <TextField type={passwordInputType}
                                    style={classes.textAreaPassword}
                                    inputRef={textAreaRef}
                                    value={props.componentConstants.password}
                                    fullWidth label={componentConstants.labelPassword}
                                    InputProps={{
                                                readOnly: true,
                                              }}
                                  />
                                <Typography style={classes.typographyCopied} variant="subtitle1" gutterBottom>
                                        {copySuccess}
                                </Typography>
                            </React.Fragment>
                            }
                        />
                </ListItem>
);
}
import React from 'react';
import CoreComponent from "../../../../../Core/CoreComponent";
import BytesButton from "./BytesButton";
import SumInputTextField from "./SumInputTextField";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SetEncryptionValuesButton from "./SetEncryptionValuesButton";

const styles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    gridItem: {
        padding: 10
    },
    gridItemFullWidth: {
        padding: 10
    }
});

export default class EncryptionDialogComponent extends CoreComponent {
    constructor(props) {
        super(props);
        this.state = {
            ones: [],
            zeros: [],
            sum: []
        }
        this.loadComponentsProps()

    }
    componentDidMount() {
        super.componentDidMount()
    }
    setEncryption(object) {
        this.setEncryption(object)
    }
    loadComponentsProps(reload = false) {
        const setEncryption = (object) => {
            if(this.props.componentMethods.setEncryption) {
                this.props.componentMethods.setEncryption(object)
            } else {

            }
        }
        const classes = this.props
        this.setComponentsProps('onesButtons', [])
            .setComponentsProps('zerosButtons', [])
            .setComponentsProps('sumInputs', [])
            .setComponentsProps('gridItem', {
                xs: 12,
                lg: 6,
                md: 12,
                className: classes.gridItem
            })
            .setComponentsProps('gridItemFullWidth', {
                xs: 12,
                lg: 12,
                md: 12,
                className: classes.gridItemFullWidth
            })
            .setComponentsProps('typography', {
                variant:"h6",
                component:"h6"
            })
            .setComponentsProps('gridItem3', {
                item: true,
                xs:3,
                lg:3,
                md:3
            })
            .setComponentsProps('gridItem9', {
                item: true,
                xs:9,
                lg:9,
                md:9
            })
            .setComponentsProps('gridItem12', {
                item: true,
                xs:12,
                lg:12,
                md:12
            })
            .setComponentsConstants('this', {
                arrayValues: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                sumArrayValues: [0, 1, 2, 3, 4, 5, 6, 7],
                sumCount: [0, 1, 2, 3, 4, 5, 6, 7],
            })
            .setComponentsProps('setEncryptionValuesButton', {

            })
            .setComponentsConstants('setEncryptionValuesButton', {
                label: this.props.componentMethods.getTranslations().LANG_SET_ENCRYPTION
            })
            .setComponentsMethods('setEncryptionValuesButton', {
                onClick: () => { this.props.componentMethods.setEncryption({ones:this.state.ones, zeros: this.state.zeros, sum: this.state.sum})}
            })
            .loadComponentsPropsButtons('onesButtons')
            .loadComponentsPropsButtons('zerosButtons')
            .loadSumValues()
        if(reload) {
            this.refresh()
        }
        return this
    }
    loadComponentsPropsButtons(componentName) {
        let buttons = this.getComponentsProps(componentName)
        let arrayToUse = this.state.ones;
        let stateKey = 'ones'
        let arrayToCheck = this.state.zeros;

        switch (componentName) {
            case 'zerosButtons':
                arrayToUse = this.state.zeros;
                stateKey = 'zeros'
                arrayToCheck = this.state.ones;
                break;
        }
        this.getComponentConstants('this').arrayValues.map((item, index) => {
            let componentMethods = {
                onClick: () => { this.onClickEncryptionButton(item, arrayToUse, stateKey) }
            }

            let buttonsComponentProps = {
                componentProps: {
                    color: 'primary',
                    variant: "outlined",
                },
                componentMethods: componentMethods,
                componentConstants: {
                    disabled: false,
                    label: item,
                    value: item,
                }
            }
            if(arrayToUse.indexOf(item) > -1) {
                buttonsComponentProps.componentProps.color = 'secondary'
                buttonsComponentProps.componentProps.variant = "contained"
            } else if(arrayToCheck.indexOf(item) > -1) {
                buttonsComponentProps.componentProps.color = 'secondary'
                buttonsComponentProps.componentProps.disabled =  true
            } else {
                buttonsComponentProps.componentProps.color = 'secondary'
            }
            buttons[index] = buttonsComponentProps;

            return null;
        })
        this.setComponentsConstants(componentName, buttons)
        return this
    }

    loadSumValues() {
        let sumInputs = this.getComponentsProps('sumInputs')
        this.getComponentConstants('this').sumArrayValues.map((item, index) => {
            const sumComponentProps = {
                componentProps: {
                    color: 'primary'
                },
                componentMethods: {
                    setSum: (idx, value) => { this.setSum(idx, value)}
                },
                componentConstants: {
                    value: item,
                    sumPosition: index,
                }
            }
            sumInputs[index] = sumComponentProps;
            return null;
        })

        this.setComponentsConstants('sumInputs', sumInputs)
        return this
    }

    getSum() {
        return parseInt(this.state.sum.join(''))
    }

    setSum(idx, value) {
        if(value === '') {
            this.removeFromSum(idx)
        } else {
            let currentSum = this.state.sum
            let sum = [0,1,2,3,4,5,6,7].map((item,i)=> {
                if(i === idx)
                    return value
                else
                if(this.state.sum[i] !== undefined && this.state.sum[i] !== null)
                    return this.state.sum[i];
                else
                    return null
            })
            this.setState({sum: sum})
        }
    }
    removeFromSum(valuePos) {
        let sum = this.state.sum
        sum[valuePos] = null
        this.setState({sum:sum})
    }
    onClickEncryptionButton(value, arrayToUse, stateKey) {
        if(arrayToUse.indexOf(value) > -1) {
            this.removeFromStateArray(value, stateKey)
        } else {
            this.appendToStateArray(value, stateKey)
        }

        return this
    }

    appendToStateArray(value, stateKey) {
        let stateObject = {
            [stateKey]: this.state[stateKey]
        }
        if(stateObject[stateKey].indexOf(value) === -1) {
            stateObject[stateKey].push(value)
            this.setState(stateObject, () => {this.loadComponentsProps(true)} )
        }
        return this
    }

    removeFromStateArray(value, stateKey) {
        let stateObject = {
            [stateKey]: this.state[stateKey]
        }
        let valuePos = stateObject[stateKey].indexOf(value)
        if( valuePos > -1) {
            stateObject[stateKey].splice(valuePos, 1)
            this.setState(stateObject, () => {this.loadComponentsProps(true)} )
        }
        return this
    }

    getRender() {
        return <React.Fragment>
            {this.getRenderZeros()}
            {this.getRenderOnes()}
            {this.getRenderSum()}
            {this.getRenderSetEncryptionValues()}
        </React.Fragment>
    }

    getRenderOnes() {
        return <Grid container>
        <Grid {...this.getComponentsProps('gridItem12')}>
            <Typography {...this.getComponentsProps('typography')}>1 ∈</Typography>
        </Grid>
        <Grid {...this.getComponentsProps('gridItem12')}>
        {
            this.getComponentConstants('this').arrayValues.map((item, index) => {
                return <BytesButton key={index} {...this.getComponentConstants('onesButtons')[index]} />
            })
        }
    </Grid>
        </Grid>
    }
    getRenderZeros() {
        return <Grid container>
        <Grid {...this.getComponentsProps('gridItem12')}>
            <Typography {...this.getComponentsProps('typography')}>0 ∈ </Typography>
        </Grid>
        <Grid {...this.getComponentsProps('gridItem12')}>
        {
            this.getComponentConstants('this').arrayValues.map((item, index) => {
                return <BytesButton key={index} {...this.getComponentConstants('zerosButtons')[index]} />
            })
        }
    </Grid>
        </Grid>
    }
    getRenderSum() {
        return  <Grid container>
        <Grid {...this.getComponentsProps('gridItems3')}>
            <Typography {...this.getComponentsProps('typography')}>Sum byte</Typography>
        </Grid>
        <Grid {...this.getComponentsProps('gridItems9')}>
        {
            this.getComponentConstants('this').sumArrayValues.map((item, index) => {
                return <SumInputTextField key={index} {...this.getComponentConstants('sumInputs')[index]} />
            })
        }
    </Grid>
        </Grid>
    }
    getRenderSetEncryptionValues() {
        return  <Grid container>
                    <Grid {...this.getComponentsProps('gridItem12')}>
                        <SetEncryptionValuesButton {...this.buildComponentProps('setEncryptionValuesButton')} />
                    </Grid>
                </Grid>
    }
}
import React from 'react';
import CoreComponent from "../../../../../Core/CoreComponent";
import LexieEncryption from "../../../../../Vendor/LexieEncryption/LexieEncryption";
import TextTextareaAutosize from "./EncryptedTextTextareaAutosize";
import Grid from '@mui/material/Grid';
import EncryptButton from "./EncryptButton";
import StringParser from "../../../../../Vendor/LexieEncryption/StringParser";
import EncryptionDialogComponent from './EncryptionDialogComponent';
export default class EncryptComponent extends CoreComponent {
    constructor(props) {

        super(props);
        this.state = {
            encryptedText: '',
            decryptedText: '',
            unEncryptedText: null
        }
        this.loadComponentsProps()
    }

    componentDidMount() {

        super.componentDidMount()

    }
    loadComponentsProps() {
        let that = this
        console.log(that.props)
        this.setComponentsMethods('encryptionDialog', {
            setEncryption: (encryptionObject) => {that.setEncryption(encryptionObject)},
            getTranslations: () => {
                return that.props.componentMethods.getTranslations()
            },
        }).setComponentsProps('gridItem4',{
            xs: 4,
            lg: 4,
            md: 4,
            item: true
        })
        .setComponentsProps('gridItem6',{
            xs: 6,
            lg: 6,
            md: 6,
            item: true
        })
        .setComponentsProps('gridItem12',{
            xs: 12,
            lg: 12,
            md: 12,
            item: true
        })
        .setComponentsMethods('buttonEncrypt', {
            encryptText: () => {that.encryptText()}
        })
        .setComponentsConstants('buttonEncrypt', { text: that.props.componentMethods.getTranslations().LANG_ENCRYPT})
        .setComponentsMethods('buttonDecrypt', {
            encryptText: () => {that.decryptText()}
        })
        .setComponentsConstants('buttonDecrypt', { text:that.props.componentMethods.getTranslations().LANG_DECRYPT })
        .setComponentsMethods('textareaUnecryptedText', {
            setValue: (e) => {that.setEncryptText(e)}
        })
        .setComponentsMethods('textareaEncryptedText', {
            setValue: (e) => {}
        })
        .setComponentsMethods('textareaDecryptedText', {
            setValue: (e) => {}
        })
        .setComponentsConstants('textareaEncryptedText', {useValue: true, value : that.state.encryptedText})
        .setComponentsConstants('textareaUnecryptedText', {useValue: false})
        .setComponentsConstants('textareaDecryptedText', {useValue: true, value : that.state.decryptedText})
        .setComponentsConstants('encryptionDialog', {lang: that.props.componentConstants.urlParams.language})
    }

    setEncryption(object) {
        const encryption = new LexieEncryption(object.zeros, object.ones, object.sum.join(''))
        this.setState({encryption: encryption})
    }
    setEncryptText(e) {
        this.setState({unEncryptedText: e.target.value}, ()=> {this.refresh()})
    }

    encryptText() {
        let managerState = this.state
        let parser = new StringParser(managerState.encryption, this.state.unEncryptedText);
        let encryptedText = parser.encryptString().join(' ')
        if(encryptedText) {
            this.setState({encryptedText:encryptedText}, () => {this.refresh()})
        }

    }

    decryptText() {
        let managerState = this.state
        let parser = new StringParser(managerState.encryption, this.state.unEncryptedText);
        let decryptedText = parser.decryptByteArray(this.state.encryptedText.split(' '))
        if(decryptedText) {
            this.setState({decryptedText:decryptedText}, () => {this.refresh()})
        }

    }

    getRender() {
        this.loadComponentsProps()
        return <React.Fragment>
            {this.getRenderEncryptionDialog()}
            {this.state.encryption && this.state.encryption.isValidEncryption()? this.getRenderGridEncrypt(): null}
        </React.Fragment>
    }
    getRenderEncryptionDialog() {
        return <EncryptionDialogComponent {...this.buildComponentProps('encryptionDialog')} />
    }
    getRenderGridEncrypt() {
        return <Grid container>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <TextTextareaAutosize {...this.buildComponentProps('textareaUnecryptedText')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <EncryptButton {...this.buildComponentProps('buttonEncrypt')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <TextTextareaAutosize {...this.buildComponentProps('textareaEncryptedText')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <EncryptButton {...this.buildComponentProps('buttonDecrypt')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <TextTextareaAutosize {...this.buildComponentProps('textareaDecryptedText')} />
                    </Grid>
                </Grid>
    }
}
/**

 */
import React, { useState, useRef } from 'react';
import Style from "../Styles/JsonInputStyle";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import Paper from '@mui/material/Paper';

export default function JsonInput (props) {
    const classes = Style();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }
    const componentMethods = {
        ...props.componentMethods,
    }
    const [json, setJson] = useState('')
    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);
    const inputFile = useRef(null);

    function copyToClipboard() {
        textAreaRef.current.select();
        document.execCommand('copy');
        setCopySuccess('Copied!');
    }
    const showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
          const text = (e.target.result)
          componentMethods.setPasswordManager(text)
        };
        reader.readAsText(e.target.files[0])
    }

    const downloadFile = async () => {
      const fileName = "passwords";
      const json = textAreaRef.current.value;
      const blob = new Blob([json],{type:'application/json'});
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + ".txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    function triggerOpenFile() {
        inputFile.current.click()
    }
    return <Paper style={{padding: 8, margin: '8px auto'}}>
                <form style={classes.root} noValidate autoComplete="off" {...componentProps}>
                    <TextField inputRef={textAreaRef} multiline rows={4} id="json" type="text" value={props.componentConstants.jsonInputValue}
                    onChange={(e) => {componentMethods.setPasswordManager(e.target.value)}}
                        fullWidth label={props.componentConstants.labelTextField} />
                    <Button onClick={() => { componentMethods.setPasswordManager('[]')} }
                        style={classes.button} variant="contained" color="secondary" disableElevation
                        endIcon={<NoteAddIcon />} >
                        {componentConstants.labelNewButton}
                    </Button>
                    <Button onClick={() => { copyToClipboard() } } style={classes.button}
                        variant="contained" color="secondary" disableElevation
                        endIcon={<FileCopyIcon />} >
                        {componentConstants.labelCopyButton}
                    </Button>
                    <Button onClick={() => { downloadFile() } } style={classes.button}
                        variant="contained" color="secondary" disableElevation
                        endIcon={<GetAppIcon />} >
                        {componentConstants.labelDownloadButton}
                    </Button>
                    <Button onClick={() => { triggerOpenFile() } } style={classes.button}
                        variant="contained" color="secondary" disableElevation
                        endIcon={<PublishIcon />} >
                        {componentConstants.labelLoadButton}
                    </Button>
                    <input style={{display:"none"}} ref={inputFile} type="file" onChange={(e) => showFile(e)} />
                </form>
            </Paper>
}
import React from 'react';
import Style from "../Styles/EncryptButtonStyle";
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export default function AuthenticateButton (props) {
    const classes = Style();

    const componentProps = {
        ...props.componentProps,
        placeholder: "Text",
        color: "primary",
        variant: "contained",
        fullWidth: true
    }
    const componentMethods = {
        onClick: props.componentMethods.encryptText
    }
    const componentConstants = {
        ...props.componentConstants,
        text: props.componentConstants.text
    }
    return <Button {...componentProps} {...componentMethods}>
        {componentConstants.text} <KeyboardArrowDownIcon />
        </Button>
}
import React, { useState } from 'react';
import Style from "../Styles/AddPasswordFormStyle";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

export default function AddPasswordForm (props) {
    const classes = Style();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }

    const componentMethods = {
        ...props.componentMethods,
    }
    const [title, setTitle] = useState('')
    const [password, setPassword] = useState('')
    const [description, setDescription] = useState('')
    if(props.componentConstants.reset) {
        setTitle('')
        setPassword('')
        setDescription('')
        props.componentConstants.reset = false;
    }
    return <Paper style={{margin: '8px auto'}}>
            <form style={classes.root} noValidate autoComplete="off" {...componentProps}>
                <Typography variant="h6">{componentMethods.getTranslations().LANG_ADD_PASSWORD}</Typography>
                <TextField style={{margin: "8px auto"}} id="title" type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}
                fullWidth label={componentMethods.getTranslations().LANG_TITLE}                />
                <TextField style={{margin: "8px auto"}} id="password" type="password" value={password} onChange={(e) => {setPassword(e.target.value)}}
                fullWidth label={componentMethods.getTranslations().LANG_PASSWORD}/>
                <TextField style={{margin: "8px auto"}} multiline rows={4} id="description" type="text" value={description}
                onChange={(e) => {setDescription(e.target.value)}} fullWidth label={componentMethods.getTranslations().LANG_DESCRIPTION} />
                <Button style={classes.button} variant="contained" color="primary" disableElevation fullWidth
                endIcon={<AddIcon />}
                onClick={() =>
                    {componentMethods.appendToPasswordManagerArray({title: title, password: password, description: description})}
                } >
                    {componentMethods.getTranslations().LANG_ADD}
                </Button>
            </form>
        </Paper>
}
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';
import Style from '../Styles/AppbarStyle'
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Settings from '../../../Settings/Settings';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TranslateIcon from '@mui/icons-material/Translate';
import PasswordIcon from '@mui/icons-material/Password';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoIcon from '@mui/icons-material/Info';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import TextsmsIcon from '@mui/icons-material/Textsms';

export default function SwipeableTemporaryDrawer(props) {
  const [age, setAge] = React.useState('');
  const classes = Style();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const handleChange = (event) => {
    componentMethods.changeLanguage(event.target.value)
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
            <a href={(componentConstants.urlParams.language ? "/" + componentConstants.urlParams.language  : "") + '/encryption'} color="primary" style={classes.menuDrawerLinks}>
              <ListItemButton color="primary">
                <ListItemIcon>
                  <InfoIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={componentMethods.getTranslations().LANG_ABOUT_LEXIE_ENCRYPTION} />
              </ListItemButton>
            </a>
        </ListItem>
        <ListItem disablePadding>

            <a href={(componentConstants.urlParams.language ? "/" + componentConstants.urlParams.language  : "") + '/password-manager'} color="primary" style={classes.menuDrawerLinks}>
              <ListItemButton color="primary">
                <ListItemIcon>
                  <PasswordIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={componentMethods.getTranslations().LANG_PASSWORD_MANAGER} />
              </ListItemButton>
            </a>
        </ListItem>
        <ListItem disablePadding>
            <a href={(componentConstants.urlParams.language ? "/" + componentConstants.urlParams.language  : "") + '/file-encryption'} color="primary" style={classes.menuDrawerLinks}>
              <ListItemButton color="primary">
                <ListItemIcon>
                  <EnhancedEncryptionIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={componentMethods.getTranslations().LANG_FILE_EXCRYPTION} />
              </ListItemButton>
            </a>
        </ListItem>
        <ListItem disablePadding>
            <a href="https://chat.lexie.xyz" color="primary" style={classes.menuDrawerLinks}>
              <ListItemButton color="primary">
                <ListItemIcon>
                  <TextsmsIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={componentMethods.getTranslations().LANG_LEXIE_CHAT} />
              </ListItemButton>
            </a>
        </ListItem>
      </List>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{componentMethods.getTranslations().LANG_LANGUAGE}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
          >
            {["en", "ro"].map((value, key) => {
            return <MenuItem key={key} value={value}>{value}</MenuItem>
            })}
          </Select>
        </FormControl>
  </  Box>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon color="secondary" />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
          {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/PasswordManagerStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import PasswordIcon from '@mui/icons-material/Password';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AddPasswordForm from './Components/AddPasswordForm';
import LexieEncryption from '../../../../Vendor/LexieEncryption/LexieEncryption';
import LexieEncryptionDialogComponent from '../../../../CommonComponents/LexieEncryptionDialog/LexieEncryptionDialogComponent'
import JsonInput from './Components/JsonInput';
import List from '@mui/material/List';
import PasswordFiledsList from './Components/PasswordFiledsList';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import StringParser from '../../../../Vendor/LexieEncryption/StringParser';
export default class PasswordManagerComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams,
        }
        this.state = {
            passwordManagerArray: [],
            filterPasswords: ''
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants,
            jsonInputValue:  JSON.stringify(this.state.passwordManagerArray),
            labelNewButton: that.props.getTranslations().LANG_NEW,
            labelCopyButton: that.props.getTranslations().LANG_COPY,
            labelDownloadButton: that.props.getTranslations().LANG_DOWNLOAD,
            labelLoadButton: that.props.getTranslations().LANG_LOAD_FILE,
            labelTextField: that.props.getTranslations().LANG_JSON_ENCRYPTED_TEXT,
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getTranslations: () => {
                return that.props.getTranslations()
            },
            changeLanguage: (language) => {
                return that.props.changeLanguage(language)
            },
            setEncryption(object) {
                const encryption = new LexieEncryption(object.zeros, object.ones, parseInt(object.sum.join('')))
                that.setState({encryption: encryption}, () => {
                    that.loadComponentsProps(true)
                })
            },
            setPasswordManager: (string) => {
                try{
                   const passwordManagerArray = JSON.parse(string)
                   that.setState({passwordManagerArray: passwordManagerArray}, () => {
                    that.loadComponentsProps(true)
                   })
                } catch(e) {
                    console.log(e)
                }

            },
            appendToPasswordManagerArray: (object) => {
                let passwordManagerArray = this.state.passwordManagerArray
                const encryption = new LexieEncryption(this.state.encryption.zeros, this.state.encryption.ones, this.state.encryption.sum)
                const parser = new StringParser(encryption, object.password);
                object.password = parser.encryptString().join(' ')
                passwordManagerArray.push(object)
                this.setState({passwordManagerArray: passwordManagerArray}, () => {
                    this.loadComponentsProps(true)
                })
                this.setComponentsConstants('addPasswordForm', {
                    reset: true
                })
            }
        })
    }
    resetEncryption() {
        this.setState({checkEncryption: null, encryption: null, passwordManagerArray: []}, () => {
            this.loadComponentsProps(true)
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        <Typography variant="h3" >{this.props.getTranslations().LANG_PASSWORD_MANAGER}</Typography>
                        {this.state.encryption ?
                        <React.Fragment>
                                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    startIcon={<LockOpenIcon />}
                                    onClick={() => {this.resetEncryption()}}
                                    >
                                    {this.props.getTranslations().LANG_RESET_ENCRYPTION}
                            </Button>
                            <JsonInput {...this.buildComponentProps('Appbar')} />
                            <AddPasswordForm {...this.buildComponentProps('Appbar')} />
                            <Paper style={{margin: "8px auto", padding: 8}}>
                                <TextField id="title" type="text" value={this.state.filterPasswords} onChange={(e) => {this.setState({filterPasswords: e.target.value})}} fullWidth label={this.props.getTranslations().LANG_FILTER_PASSWORDS} />
                            </Paper>
                            {this.state.passwordManagerArray.length > 0 ?
                                <Paper style={{margin: "8px auto", padding: 8}}>
                                    <List>
                                        {
                                            this.state.passwordManagerArray.map((value, index) => {
                                                const that = this

                                                const componentConstants = {
                                                    ...value,
                                                    labelAddButton: that.props.getTranslations().LANG_ADD,
                                                    labelCopied: that.props.getTranslations().LANG_COPIED,
                                                    encryption: this.state.encryption
                                                }
                                                const componentMethods = {
                                                    removeFromPasswordManager: () => {
                                                        let passwordManagerArray = this.state.passwordManagerArray
                                                        passwordManagerArray.splice(index, 1)
                                                        this.setState({passwordManagerArray: passwordManagerArray}, () => {
                                                            that.loadComponentsProps(true)
                                                        })
                                                    }
                                                }
                                                if(this.state.filterPasswords != '' && value.title.toLowerCase().indexOf(this.state.filterPasswords.toLowerCase()) === -1 && value.description.toLowerCase().indexOf(this.state.filterPasswords.toLowerCase()) === -1)
                                                    return null
                                                else 
                                                return <React.Fragment key={index}>
                                                            <PasswordFiledsList key={index} componentMethods={componentMethods} componentConstants={componentConstants} />
                                                            <Divider />
                                                        </React.Fragment>
                                            })
                                        }
                                    </List>
                                </Paper>
                            :
                            null}
                        </React.Fragment>
                        :
                        <LexieEncryptionDialogComponent  {...this.buildComponentProps('Appbar')} />
                        }
                    </div>
                </div>
    }
}

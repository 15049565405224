import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/AboutStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import PasswordIcon from '@mui/icons-material/Password';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AboutPage from './Components/AboutPage';
import EncryptComponent from './Components/EncryptComponent';

export default class AboutLexieEncryption extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getTranslations: () => {
                return that.props.getTranslations()
            },
            changeLanguage: (language) => {
                return that.props.changeLanguage(language)
            }
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        <AboutPage  {...this.buildComponentProps('Appbar')} />
                        <Paper style={{padding: 8, margin: "8px auto"}}>
                            <EncryptComponent {...this.buildComponentProps('Appbar')} />
                        </Paper>
                    </div>
                </div>
    }
}

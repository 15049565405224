import React, { useState, useRef } from 'react';
import Style from "../Styles/FileEncrypterStyle";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import FileParser from '../../../../../Vendor/LexieEncryption/FileParser'
import LexieEncryption from "../../../../../Vendor/LexieEncryption/LexieEncryption";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function Encrypter (props) {
    const classes = Style();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }
    const componentMethods = {
        ...props.componentMethods,
    }
    const [encrypting, setEncrypting] = useState(false)
    const [crypted, setCrypted] = useState(false)
    const [encryptFileName, setEncryptFileName] = useState('');
    const textAreaRef = useRef(null);
    const inputFile = useRef(null);

    const showFile = async (e) => {
        e.preventDefault()
        setEncrypting(true)
        setCrypted(false)
        const encryption = new LexieEncryption(props.componentConstants.lexieEncryption.zeros,
            props.componentConstants.lexieEncryption.ones,
            props.componentConstants.lexieEncryption.sum)
        const fileParser = new FileParser(encryption, setInputValue)
        setEncryptFileName(e.target.files[0].name + '.lar')
        fileParser.encryptFile(e.target.files[0])
    }
    const setInputValue = (value) => {
        setEncrypting(false)
        setCrypted(true)
        if(typeof value === 'object')
            textAreaRef.current.value = JSON.stringify(value)
        else
            textAreaRef.current.value = value
    }
    const downloadFile = async () => {
      const fileName = encryptFileName;
      const json = textAreaRef.current.value;
      const blob = new Blob([json],{type:'application/json'});
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    function triggerOpenFile() {
        inputFile.current.click()
    }
    return <form className={classes.root} noValidate autoComplete="off" {...componentProps}>
            <Typography variant="h6" gutterBottom>
                {props.componentConstants.labelEncryptorTitle}
            </Typography>
            <TextField inputRef={textAreaRef} className={classes.textArea} multiline rows={4} id="json" type="text"
                       value={props.componentConstants.jsonInputValue}
            onChange={(e) =>
                {componentMethods.setPasswordManager(e.target.value)}}
                fullWidth label={props.componentConstants.labelTextField} />
            {encrypting ?   <React.Fragment><CircularProgress /><Typography variant="subtitle2" gutterBottom color="primary">
                                {props.componentConstants.encryptingText}
                            </Typography></React.Fragment>: null }
            {crypted ? <Button onClick={() => { downloadFile() } } className={classes.button}
                             variant="contained" color="primary" disableElevation
                             endIcon={<GetAppIcon />} >
                            {componentConstants.labelDownloadButton}
                        </Button>
            :
                null}
            {!encrypting ? <Button onClick={() => { triggerOpenFile() } } className={classes.button}
                            variant="contained" color="primary" disableElevation
                            endIcon={<PublishIcon />} >
                                {componentConstants.labelLoadButton}
                            </Button>
            :null}
            <input style={{display:"none"}} ref={inputFile} type="file" onChange={(e) => showFile(e)} />
        </form>
}
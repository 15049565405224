import React from 'react';
import Style from "../Styles/EncryptedTextTextareaAutosizeStyle";
import TextField from '@mui/material/TextField';


export default function EncryptedTextTextareaAutosize (props) {
    const classes = Style();
    const componentProps = {
        placeholder: "Text",
        "aria-label": "minimum height",
        style: classes.root,
        multiline: true,
        rows: 4
    }
    if(props.componentConstants.useValue)
        componentProps.value = props.componentConstants.value
    const componentMethods = {
        onChange: props.componentMethods.setValue
    }
    return <TextField {...componentProps}  {...componentMethods} />
}
export default function Style(theme) {
    const style = {
        root: {
            flexGrow: 1,
            padding: 20
        },
        button: {
            marginTop: 8
        }
    }
    return style
}
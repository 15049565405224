import React from 'react';
import TextField from '@mui/material/TextField';
import Style from "../Styles/EncryptionInputTextFieldStyle";

export default function OnesButton (props) {
    const classes = Style();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentProps = {
        ...props.componentProps,
        style: classes.root
    }

    return <TextField {...componentProps} {...props.componentProps} />
}
import React, { useState, useRef } from 'react';
import Style from "../Styles/FileEncrypterStyle";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import FileParser from '../../../../../Vendor/LexieEncryption/FileParser'
import LexieEncryption from "../../../../../Vendor/LexieEncryption/LexieEncryption";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';


export default function UnarchiveInput (props) {
    const classes = Style();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }
    const componentMethods = {
        ...props.componentMethods,
    }
    const [decrypting, setDecrypting] = useState(false)
    const [fileLoaded, setFileLoaded] = useState(false)
    const [encryptFileName, setEncryptFileName] = useState('');
    const textAreaRef = useRef(null);
    const inputFile = useRef(null);

    const showFile = async (e) => {
        setDecrypting(true)
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            textAreaRef.current.value = e.target.result
            downloadFile()
            setDecrypting(false)
        };
        reader.readAsText(e.target.files[0])
    }
    const setInputValue = (value) => {
        if(typeof value === 'object')
            textAreaRef.current.value = JSON.stringify(value)
        else
            textAreaRef.current.value = value
    }
    const downloadFile = async () => {
        const fileName = JSON.parse(textAreaRef.current.value).filename;
        const encryption = new LexieEncryption(props.componentConstants.lexieEncryption.zeros,
            props.componentConstants.lexieEncryption.ones,
            props.componentConstants.lexieEncryption.sum)
        const fileParser = new FileParser(encryption, setInputValue)
        const json = fileParser.unarchiveFile(JSON.parse(textAreaRef.current.value))
        const blob = new Blob([json],{type:JSON.parse(textAreaRef.current.value).type});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }
    function triggerOpenFile() {
        inputFile.current.click()
    }
    return <form className={classes.root} noValidate autoComplete="off" {...componentProps}>
            <Typography variant="h6" gutterBottom>
                {props.componentConstants.labelDecryptorTitle}
            </Typography>
            <TextField inputRef={textAreaRef} className={classes.textArea} multiline rows={4} id="json" type="text"
                       value={props.componentConstants.jsonInputValue}
            onChange={(e) => {componentMethods.setPasswordManager(e.target.value)}}
                fullWidth label={props.componentConstants.labelTextField} />
            {decrypting ?   <React.Fragment><CircularProgress /><Typography variant="subtitle2" gutterBottom color="primary">
                {props.componentConstants.dencryptingText}
            </Typography></React.Fragment>: null }
            {fileLoaded ? <Button onClick={() => { downloadFile() } } className={classes.button}
                variant="contained" color="primary" disableElevation
                endIcon={<GetAppIcon />} >
                {componentConstants.labelDownloadButton}
            </Button>
            :
            null}
            {!decrypting ? <Button onClick={() => { triggerOpenFile() } } className={classes.button}
                variant="contained" color="primary" disableElevation
                endIcon={<PublishIcon />} >
                {componentConstants.labelLoadButton}
            </Button>
            :null}
            <input style={{display:"none"}} ref={inputFile} type="file" onChange={(e) => showFile(e)} />
        </form>
}
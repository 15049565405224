import React from "react";
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import RouterSwitch from "./RouterSwitch";
import Languages from "../../../Languages/Languages";
import Settings from '../Settings/Settings'
import Theme from '../../../Themes/ThemeArtLexie'
import { ThemeProvider } from '@mui/material/styles';


class RouterComponent extends React.Component {
    constructor(props) {
        super(props);
        let lang = Languages[Settings.defaultLanguage]

        this.state = {
            language: lang,
            subscribersFeedItems: [],
            searchFeedItems: [],
            subscribers: [],
            errors: [],
            loadingNewsItem: true,
            loadingNewsFeed: false,
            trendingWords: [],
            bookmarks: [],
            createAccountYears: {
                items: []
            },
            zopinions: [],
            words: [],
            wordsPagination: {
                last: 1
            }
        }
    }
    setLanguage (lang)
    {
        this.setState({language: Languages[lang]})
    }
    componentDidMount() {
    }
    clearErrorMessage(index) {
        let errorsMessages = this.state.errors
        errorsMessages.splice(index, 1);
        this.setManagerState({errors: errorsMessages})
    }
    setManagerState(stateObject, callback = () => {}) {
        if(stateObject) {
            this.setState(stateObject,() => {
                this.refresh(callback)
            })
        }
    }

    getManagerState() {
        return this.state
    }
    render() {

          return <ThemeProvider theme={Theme}>
                    <Router>
                        <RouterSwitch
                        getManagerState={() => { return this.getManagerState()}}
                        websocketSend={(data, type)=>{this.websocketSend(data, type)}}
                        setManagerState={(stateObject, callback) => {this.setManagerState(stateObject, callback)}}
                        setScrollPosition={(scrollPos) => {this.setScrollPosition(scrollPos)}}
                        clearErrorMessage={(index) => {this.clearErrorMessage(index)}}
                        setLanguage={(lang) => { this.setLanguage(lang)}}
                        />
                    </Router>
                </ThemeProvider>
    }
    refresh(callback = () => {}) {
        this.forceUpdate()
        callback()
    }
}

export default RouterComponent
import React from 'react';
import TextField from '@mui/material/TextField';
import Style from "../Styles/SumInputTextFieldStyle";

export default function OnesButton (props) {
    const classes = Style();

    const componentConstants = {
        value: props.componentConstants.value,
        sumPosition: props.componentConstants.sumPosition
    }

    const componentProps = {
        id: "sum"+props.componentConstants.index,
        label: "",
        style: classes.root,
        maxLength: 1,
        InputProps: {
            inputProps: {
                maxLength: 1,
            }
        },
    }
    const setSum = function (e) {
        props.componentMethods.setSum(props.componentConstants.sumPosition, e.target.value)
    }
    return <TextField {...componentProps} {...props.componentProps} onChange = {(e) => {setSum(e)}} />
}